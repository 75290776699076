#hero {
  width: 100%;
  height: 90vh;
  margin-bottom: -200px;
  position: relative;
  overflow: hidden;
}

.slider-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.slider-item {
  width: 100%;
  height: 100%;
}

.slider-item img {
  width: 100%;
  height: 100%;
  object-fit: contain !important;

}

@media (min-width: 768px) {
  .slider-item img {
    object-fit: contain !important;
  }
}

.slider-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(127, 201, 109, 0.5); /* Soft yellow with 0.5 opacity */
  }
#hero h1 {
  margin: 0;
  font-size: 35px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  font-family: "Lucida Handwriting", cursive !important;

  /* color: var(--red-color); */
}

#hero h2 {
  color: var(--g-color);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 35px;
  margin-top: 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: var(--green-color);
}

/* Rest of your existing CSS styles for the Hero section */