
/*--------------------------------------------------------------
# principles
--------------------------------------------------------------*/
@import "variables.css";


.principles .icon-box {
  border: 1px solid #d5e1ed;
  padding: 20px 10px;
  border-radius: 15px;
  margin-bottom: 15px;

  transition: all ease-in-out 0.3s;
}

.principles .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: var(--green-color);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  transform-style: preserve-3d;
  position: relative;
  z-index: 2;
}

.principles .icon-box .icon svg {
  color: #fff;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.principles .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: rgba(25, 119, 204, 0.2);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
  z-index: -1;
}

.principles .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.principles .icon-box h4 a {
  color: #2c4964;
}

.principles .icon-box h5 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}

.principles .icon-box h5 a {
  color: #2c4964;
}
.principles .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  text-align: start;
  font-size: 17px;


}

.principles .icon-box:hover {
  background: var(--green-color);
  border-color: var(--green-color);
}

.principles .icon-box:hover .icon {
  background: #fff;
}

.principles .icon-box:hover .icon svg {
  color: var(--green-color);
}

.principles .icon-box:hover .icon::before {
  background: rgba(255, 255, 255, 0.3);
}

.principles .icon-box:hover h4 a,
.principles .icon-box:hover h5 a,
.principles .icon-box:hover p {
  color: #fff;
}
