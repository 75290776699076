h2 {
    text-align: center;
    padding: 20px;
  }
  
  /* Slider */
  
  .partner-logos .slick-slide {
    margin: 0px 30px;
    border-radius: 20px;
    width: 150px !important; /* Adjust the width to your desired value */

  }
  
  .partner-logos .slick-slide img {
    width: 100%;
  }
  
  .partner-logos .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  
  .partner-logos .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .partner-logos .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  
  .partner-logos .slick-slide img {
    display: block;
  }
  
  .partner-logos .slick-initialized .slick-slide {
    display: block;
  }
  
  .partner-logos .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  
  .partner-logos .slick-arrow.slick-hidden {
    display: none;
  }