.back-to-top svg {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #ffffff;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  .datepicker-dropdown {
    padding: 20px !important;
  }
  
  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: #cf1e1a;
    height: 50px;
    font-size: 14px;
    transition: all 0.5s;
    z-index: 996;
  }
  
  #topbar.topbar-scrolled {
    top: -40px;
  }
  
  #topbar .contact-info a {
    line-height: 1;
    color: #ffffff;
    transition: 0.3s;
  }
  .contact-info {
    display: flex;
    align-items: center;
  }
  
  .contact-info h6 {
    margin-left: 10px; /* Adjust the margin as per your preference */
    padding-left: 10px; /* Adjust the padding as per your preference */
    border-left: 1px solid #ccc; /* Add a vertical separator line */
    color:white !important;
  }
  
  .contact-info a,
  .contact-info a:hover {
    text-decoration: none;
    color: inherit;
  }
  
  .contact-info svg {
    margin-right: 5px; /* Adjust the margin as per your preference */
  }
  
  #topbar .contact-info a:hover {
    color: #ffffff;
  }
  
  #topbar .contact-info svg {
    color: #ffffff;
    padding-right: 4px;
    margin-left: 15px;
    line-height: 0;
  }
  
  #topbar .contact-info svg:first-child {
    margin-left: 0;
  }
  
  #topbar .social-links a {
    color: #ffffff;
    padding-left: 15px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
  }
  
  #topbar .social-links a:hover {
    color: #ffffff;
  }
  
  #topbar .social-links a:first-child {
    border-left: 0;
  }