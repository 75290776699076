
/* --------------------------------------------------------------*/


a {
  color: var(--green-color);
  text-decoration: none;
}

a:hover {
  color: var(--green-color);
  text-decoration: none;
}



@font-face {
  font-family: 'Amagro';
  /* src: url('./app/fonts/amagro/Amagro.ttf') format('truetype'); */
  src: url('./app/fonts/Josefin_Sans/static/JosefinSans-Regular.ttf') format('truetype');
  /* Add additional src lines for other font variations (e.g., bold) if available */
}

body {
  font-family: 'Amagro', sans-serif;
}

/* Apply the 'Amagro' font style to specific elements */
h1, h2, h3, h4, h5,h6,p,a {
  font-family: 'Amagro', sans-serif;
}
p{
  font-size: 15px !important;
}
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--green-color);
  border-top-color: #d1e6f9;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
:root {
  scroll-behavior: smooth;
}