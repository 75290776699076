/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
@import "variables.css";
section {
    padding: 60px 0;
    overflow: hidden;
  }

  .section-bg {
    background-color: #f1f7fd;
  }

  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }

  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #2c4964;
  }

  .section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: var(--green-color);
    bottom: 0;
    left: calc(50% - 20px);
  }

  .section-title p {
    margin-bottom: 0;
  }

  .ListGroup {
    list-style: none;
    padding: 0;
  }

  .list-group-item {
    display: flex;
    align-items: center;
    padding: 10px !important;
  }

/* p{
  text-align: justify;
} */
.list-group-item h6.highlight
 {
  margin: 0;
  font-weight: bold;
  background-image: linear-gradient(to right, var(--red-color), var(--orange-color),var(--green-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: "Lucida Handwriting", cursive !important;


  }
  .list-group-item::before {
    /* content: "•"; Replace with your desired bullet point or symbol */
    position: absolute;
  left: 10px  !important;
  }

  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 20px 0;
    background-color: #f1f7fd;
    min-height: 40px;
    margin-top: 120px;
  }

  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 100px;
    }
  }

  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
  }

  @media (max-width: 992px) {
    .breadcrumbs h2 {
      margin: 0 0 10px 0;
    }
  }

  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  .breadcrumbs ol li+li {
    padding-left: 10px;
  }

  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }

  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }

    .breadcrumbs ol {
      display: block;
    }

    .breadcrumbs ol li {
      display: inline-block;
    }
  }

  /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
  .why-us .content {
    padding: 30px;
    background: var(--green-color);
    border-radius: 4px;
    color: #fff;
  }

  .why-us .content h3 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
  }

  .why-us .content p {
    margin-bottom: 30px;
  }

  .why-us .content .more-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
  }

  .why-us .content .more-btn svg {
    font-size: 14px;
  }

  .why-us .content .more-btn:hover {
    color: var(--green-color);
    background: #fff;
  }

  .why-us .icon-boxes .icon-box {
    text-align: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    width: 100%;
  }

  .why-us .icon-boxes .icon-box svg {
    font-size: 40px;
    color: var(--green-color);
    margin-bottom: 30px;
  }

  .why-us .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 30px 0;
  }

  .why-us .icon-boxes .icon-box p {
    font-size: 15px;
    color: #848484;
  }