.custom-tab-header .nav-link.active {
    background-color: var(--green-color);;
  }
  .custom-tab-header .nav-link {
    color: black;
  }
  .card-footer button{
    background-color: var(--green-color);
  }
  .btn-primary{
    background-color: var(--green-color);
  }