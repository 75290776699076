/* CustomListGroup.css */

.list-group .list-group-item {
   
    border-right: none; /* Remove bottom border */
    border-left: none; /* Remove bottom border */
display: flex;
 justify-content: center;
    border-top: none; /* Remove bottom border */
    padding: 5px; /* Adjust padd
    list-style: none !important; /* Remove default list style */
    /* border-bottom-right-radius: 90px;
    border-bottom-left-radius: 90px; */

    margin-left: 5px !important; /* Remove left padding to adjust for the missing bullet */
    color:black !important;
  }
  .list-group .list-group-item:hover {

    background-color: #e9cd52;
    color: white;
 
  }
  .list-group .list-group-item h5{
    color: black;
    text-decoration: none;
 

  }
  .list-group .list-group-item h5:hover{
    color: white;
  }