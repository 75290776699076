.news-card {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .news-card .news-card-image {
    position: relative;
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
  }
  
  .news-card .news-card-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .news-card .news-card-content {
    padding: 20px;
    height: calc(100% - 200px); /* Adjust the height based on image height and padding */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .news-card .news-card-content .card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .news-card .news-card-content .card-text {
    margin-top: 10px;
  }
  
  .news-card .news-card-content .d-flex {
    margin-top: 20px;
  }
  
  .news-card .news-card-content .d-flex .text-muted {
    margin-left: 5px;
  }
  
  .news-card .news-card-content .mt-auto {
    margin-top: auto;
  }