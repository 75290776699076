
/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
@import "variables.css";

.counts {
  background: #f1f7fd;
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #fff;
}

.counts .count-box svg {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  background: var(--green-color);
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #082744;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}